import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import { CallToAction } from '../../components/CallToAction'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import Default from '../../components/Layouts/Default';

import IntelligentPhoneNumbers from '../images/intelligentPhoneNumbers.png'
import SfHalf from '../images/sfHalf.png'
import TeamDashHalf from '../images/teamDashHalf.svg'
import LibertyRing from '../animations/libertyRing.json'
import SalesforceGraph from '../images/salesforceGraph.png';
import ActionEvent from '../images/actionEvent.svg';
import BestSalesforce from '../images/bestSalesforce.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'




var lottie

export default class BetterCallCenter extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Conversation Intelligence in Salesforce</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony1'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Revenue Process Automation
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              Drive Repeatable Execution Across Your Entire Funnel (All In Salesforce)
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} py={['6rem', '0rem']} background={colors.trulyDark}>
            <Box width={[1/2, 1/3]}>
              <CenteredImg src={BestSalesforce} alt="Intelligent Phone Numbers"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
              QUANTIFY ACTIVITY LIKE NEVER BEFORE
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Remove all of the limitations of Salesforce (Task field limitations, search limitations, JOIN limitations, etc) through a new and improved data structure.
                <br/><br/>
                Enhance the data on your logged activities by using AI to transcribe and analyze your spoken/written conversations (Talktime Percentage, #Question Asked, Sentiment Score, etc).
                <br/><br/>
                Analyze all of your activities and opportunities to quantify every aspect of your customer engagement (Time In Stage, Talktime in Stage, Customer Responsiveness, etc).
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid color='#292929'>
          <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              CONTINUOUSLY INSPECT YOUR TEAM/FUNNEL AGAINST YOUR PROCESS
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
                Highly customizable trackers allow you to detect deviations from your desired process.
              <br/><br/>
                <u><b> Sales Coaching </b></u> - identify behavioral patterns that adversely impact performance like talking too much, not asking enough questions, not using correct language, taking too long to respond to emails, etc.
              <br/><br/>
                <u><b> Opportunity Execution </b></u> - identify deviations from your process like deals being open too long, reps not following up on deals, proposals not being submitted on time, etc.
              <br/><br/>
                <u><b> Forecasting </b></u> - identify deal risks like customers going cold, mentioning competitor names later in deals, etc.

              </Large>
            </Box>
            <Box width={[1/2, 1/2]} p="32pt">
              <CenteredImg src={SalesforceDashboard} alt="Salesforce"/>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '0rem']}>
            <Box width={[1/2, 1/2]} pb="2rem">
              <CenteredImg src={ActionEvent} alt="Team Dashboard"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              AUTOMATE THE REVENUE PROCESS WITHOUT CODE
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Guide your reps to execute the right next steps through 'next step' tasks and notifications.
              <br/><br/>
              Deliver easily digestible insights on pipeline health to your managers and leaders.
              <br/><br/>
              Automate the customer journey based on highly customizable deal triggers.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperBackground color={colors.trulyDark} background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
